<template>
    <div>
      <!-- Start Header Area -->
      <Header>
        <img slot="logo" :src="logo" />
      </Header>
      <!-- End Header Area -->
      <!-- Start Policy Page  -->
      <div class="error-page-inner bg_color--4">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner">
                <h1 class="heading-title theme-gradient">Política de Privacidade</h1>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"/>
            <v-col cols="10">
                <div class="policy-box">
                    <p>Antes de preencher o formulário, pedimos que leia e aceite a nossa Política de Privacidade. Nela, explicamos como coletamos, usamos e protegemos suas informações pessoais. Ao continuar, você concorda com os termos da nossa Política de Privacidade.</p>
                    <div v-for="item in policies" :key="item.title" class="policy-details">
                        <p class="item-title">{{ item.title }}</p>
                        <p>{{ item.details }}</p>
                    </div>
                    <div class="checkbox">
                        <v-checkbox 
                            v-model="accept"
                            :value="True">
                        </v-checkbox>
                        <p class="item-title">Eu li e concordo com a Política de Privacidade do site, e autorizo a coleta e o processamento dos meus dados pessoais para os fins descritos na Política de Privacidade.</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="1"/>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="inner">
                <div class="error-button">
                  <a class="btn-default btn-border btn-opacity button-join"
                    :disabled="!accept" 
                    :event="accept ? 'click' : ''"
                    :class="{ disabled: !accept }"
                    target="_blank"
                    href="https://online.forms.app/aalsamman/foreverjobsbrasil"
                    >Junte-se a nós</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Policy Page  -->
      <Footer />
    </div>
  </template>
  
  <script>
    import Header from "../components/Header";
    import Footer from "../components/Footer";
    export default {
      components: {
        Header,
        Footer,
      },
      data() {
        return {
          logo: require("../assets/images/logo-sm.png"),
          accept: false,
          policies: [
            {
                title: 'Política de Privacidade',
                details: 'Esta Política de Privacidade descreve como coletamos, usamos e armazenamos suas informações pessoais quando você se cadastra em nosso site.'
            },
            {
                title: 'Coleta de Informações',
                details: 'Podemos coletar informações pessoais quando você se cadastra em nosso site, como nome, endereço de e-mail, telefone, fotos e outras informações de contato. Também podemos coletar informações sobre suas habilidades e experiência profissional.'
            },
            {
                title: 'Uso de Informações',
                details: 'Usamos suas informações pessoais para entrar em contato com você quando tivermos eventos ou missões que correspondam ao seu perfil e às necessidades de nossos clientes. Também podemos usar suas informações pessoais para fornecer suporte técnico, enviar informações sobre nossos produtos e serviços, responder a suas perguntas ou solicitações, e para outros fins relacionados ao funcionamento do site.'
            },
            {
                title: 'Compartilhamento de Informações',
                details: 'Podemos compartilhar suas informações pessoais com nossos clientes para os fins descritos acima. Também podemos compartilhar suas informações pessoais com provedores de serviços terceirizados que nos ajudam a operar o site ou a fornecer serviços relacionados, como provedores de hospedagem, processadores de pagamentos ou fornecedores de análises de dados.'
            },
            {
                title: 'Armazenamento de Informações',
                details: 'Armazenamos suas informações pessoais apenas pelo tempo necessário para cumprir os fins para os quais foram coletadas, ou conforme exigido por lei. Tomamos medidas razoáveis para proteger suas informações pessoais contra perda, uso indevido e acesso não autorizado.'
            },
            {
                title: 'Seus Direitos',
                details: 'Você tem o direito de acessar, corrigir e excluir suas informações pessoais, bem como de se opor ao processamento dessas informações. Você também tem o direito de retirar seu consentimento para o processamento de suas informações pessoais a qualquer momento.'
            },
            {
                title: 'Entre em Contato',
                details: 'Se você tiver perguntas ou preocupações sobre esta Política de Privacidade ou sobre como suas informações pessoais são tratadas, entre em contato conosco através do endereço de e-mail fornecido em nosso site.'
            },
            {
                title: 'Atualizações',
                details: 'Podemos atualizar esta Política de Privacidade periodicamente, para refletir mudanças em nossas práticas de coleta e uso de informações pessoais. Recomendamos que você reveja esta política regularmente para se manter informado sobre como suas informações pessoais estão sendo usadas e protegidas.'
            }
          ]
        };
      },
    };
  </script>
  <style lang="scss" scoped>
  .heading-title {
    font-size: 32px !important;
  }
  .policy-box {
    height: 400px;
    overflow: auto;
    margin: 40px 0;
    background-color: white;
    padding: 10px;
    .item-title {
        font-size: 12px;
        font-weight: 600;
        margin: 0;
    }
    p {
        font-size: 12px;
    }
    .checkbox {
        display: flex;
        .item-title {
            font-size: 10px;
            font-weight: 600;
            margin: 0;
            line-height: 15px;
            padding-top: 25px;
        }
    }
  }
  .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
  </style>
  